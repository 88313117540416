import axios from 'axios'
//const url = 'https://api.gpdf.com.br/apisocio/apiopen/'
const url = 'http://localhost:8091/apiopen/'


export const httpopen = axios.create({
     baseURL: url, 
})

export default {
     url,
}