import { http } from './config'
import { httpopen } from './configOpen'

import authHeader from './auth-header';

export default {

    lista: () => {
        return http.get('/usuario/lista_user', { headers: authHeader() })
    },

    updateStatusUsuario: (id) => {
        return http.get('/usuario/alterarstatus/'+ id,  { headers: authHeader() })
    },

    updatePermissao: (id, roles) => {
        return http.post('/usuario/permissoes/'+ id, roles, { headers: authHeader() })
    },

    smsAtivacao: (id) => {
        return http.get('/usuario/tokensms/'+ id, { headers: authHeader() })
    },

    ativarToken: (codigo) => {
        return httpopen.get('/usuario/ativarcodigo/'+ codigo)
    },

    recuperarSenha: (telefone) => {
        return httpopen.get('/usuario/recuperarsenha/'+ telefone)
    },

    updateSenhaUsuario: (senhaNova, SenhaAntiga) => {
        const params = new URLSearchParams([['senhaNova', senhaNova], ['SenhaAntiga', SenhaAntiga]])
        const user = JSON.parse(sessionStorage.getItem('user'))
        return http.get('/usuario/alterarsenha/'+ user.id, { params , headers: authHeader() })
    },

    cadastro: (signUpRequest) => {
        return http.post('/usuario/cadastro/', signUpRequest, { headers: authHeader() })
    },
   
    editar: (signUpRequest) => {
        return http.post('/usuario/editar/', signUpRequest, { headers: authHeader() })
    },
   
    lista_permissoes: (user) => {
        return http.get('/usuario/roles_user', user, { headers: authHeader() })
    },

    logs: () => {
        return http.get('/usuario/log/', { headers: authHeader() })
    },


    



}
